'use client';

import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Button, Container } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Locale } from '../../i18n-config';
import Link from 'next/link';

const ServerError = ({ message }: { message?: string }) => {
  const params = useParams();
  const { t } = useTranslation((params?.lang as Locale) || 'en');

  return (
    <>
      <div className="error-page min-vh-100 d-flex">
        <Container fluid={'lg'} className="my-auto">
          <div className="lk-error-page server">
            <LazyLoadImage
              className="img-fluid"
              src="https://cdn.pinkswan.ch/application/LUMBERJACK/2208240651_500.png"
              alt="L-K.io"
              height={400}
              width={400}
            />
            <h2 className="text-center">{t('ServerError/key2')}</h2>
            <p>{message ? message : t('ServerError/key3')}</p>
            <Link href={'/'}>
              <Button variant="warning" className="rounded-3 text-white">
                {t('ServerError/key1')}
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ServerError;
